import {
  faPlusSquare,
  faList,
  faUsers,
  faWallet,
  faMoneyCheckAlt,
  faMoneyBillAlt,
  faInfoCircle,
  faListAlt,
  faFileInvoiceDollar,
  faHandHoldingUsd,
  faUserAlt,
  faChartLine,
  faCommentDollar,
  faBook,
  faFileInvoice,
  faExclamationTriangle,
  faComments,
  faUserPlus,
  faUserNinja,
  faUserTag,
  faUserCheck,
  faLifeRing,
  faInfo,
  faShareAltSquare,
  faUsersCog,
  faThList,
  faFileVideo,
  faVideo,
  faPhotoVideo,
  faCheckDouble,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";
import { _hasRight } from "./admin_rights";
import { _isAgent } from "./user_rights";

export const getAdminServices = [
  {
    name: "Agent statistics",
    path: "agentstatistic",
    icon: faUsersCog,
    tags: ["agents", "users", "add", "new"],
    categories: "agents",
    rights: "VIEW_USERS",
    group_by_vendor: true,
    vendor: {
      code: "USER_MANAGEMENT",
      display_name: "User Management",
      icon: faUserAlt,
      rights: "VIEW_USERS",
    },
  },
  {
    name: "Add New Agent",
    path: "add",
    icon: faUserPlus,
    tags: ["agents", "users", "add", "new"],
    categories: "agents",
    rights: "CREATE_USERS",
    group_by_vendor: true,
    vendor: {
      code: "USER_MANAGEMENT",
      display_name: "User Management",
      icon: faUserAlt,
      rights: "VIEW_USERS",
    },
  },
  {
    name: "Registered Agents",
    path: "list",
    icon: faList,
    tags: ["agents", "users", "list"],
    categories: "agents",
    rights: "VIEW_USERS",
    group_by_vendor: true,
    vendor: {
      code: "USER_MANAGEMENT",
      display_name: "User Management",
      icon: faUserAlt,
    },
  },
  {
    name: "KYC Checks",
    path: "kyc",
    icon: faUserCheck,
    tags: ["agents", "users", "kyc", "check"],
    categories: "agents",
    rights: "APPROVE_USERS",
    group_by_vendor: true,
    vendor: {
      code: "USER_MANAGEMENT",
      display_name: "User Management",
      icon: faUserAlt,
    },
  },
  {
    name: "Super agent applicants",
    path: "superAgentApplicatants",
    icon: faThList,
    tags: ["agents", "super"],
    categories: "agents",
    rights: "APPROVE_USERS",
    group_by_vendor: true,
    vendor: {
      code: "USER_MANAGEMENT",
      display_name: "User Management",
      icon: faUserAlt,
      rights: "VIEW_USERS",
    },
  },
  {
    name: "Admin Users",
    path: "staff",
    icon: faUserNinja,
    tags: ["admin", "users", "list"],
    categories: "agents",
    rights: "VIEW_USERS",
    group_by_vendor: true,
    vendor: {
      code: "USER_MANAGEMENT",
      display_name: "User Management",
      icon: faUserAlt,
    },
  },
  {
    name: "Super  Agent",
    path: "superagent",
    icon: faUsersCog,
    tags: ["agents", "super"],
    categories: "agents",
    rights: "VIEW_USERS",
    group_by_vendor: true,
    vendor: {
      code: "USER_MANAGEMENT",
      display_name: "User Management",
      icon: faUserAlt,
      rights: "VIEW_USERS",
    },
  },
  {
    name: "Sub  Agent",
    path: "listsubagent",
    icon: faListAlt,
    tags: ["agents", "sub"],
    categories: "agents",
    rights: "VIEW_USERS",
    group_by_vendor: true,
    vendor: {
      code: "USER_MANAGEMENT",
      display_name: "User Management",
      icon: faUserAlt,
      rights: "VIEW_USERS",
    },
  },
  {
    name: "Agent self Application",
    path: "listSelfApplication",
    icon: faUsers,
    tags: ["agents", "application","list"],
    categories: "agents",
    rights: "VIEW_USERS",
    group_by_vendor: true,
    vendor: {
      code: "USER_MANAGEMENT",
      display_name: "User Management",
      icon: faUserAlt,
      rights: "VIEW_USERS",
    },
  },
  {
    name: "Approve Credit Request",
    path: "approveCreditRequest",
    icon: faCheckSquare,
    tags: ["wallets", "approve", "float"],
    categories: "wallets",
    rights: "APPROVE_CREDIT_REQUESTS",
    group_by_vendor: true,
    vendor: {
      code: "TOPUP_MANAGEMENT",
      display_name: "Topup Management",
      icon: faCommentDollar,
    },
  },
  {
    name: "Pending Credit Requests",
    path: "creditRequest",
    icon: faCommentDollar,
    tags: ["wallets", "approve", "reject", "float"],
    categories: "wallets",
    rights: "APPROVE_CREDIT_REQUESTS",
    group_by_vendor: true,
    vendor: {
      code: "TOPUP_MANAGEMENT",
      display_name: "Topup Management",
      icon: faCommentDollar,
    },
  },
  {
    name: "Generate Credit Request",
    path: "generateRequest",
    icon: faPlusSquare,
    tags: ["wallets", "request", "generate"],
    categories: "wallets",
    rights: "CREATE_CREDIT_REQUESTS",
    group_by_vendor: true,
    vendor: {
      code: "TOPUP_MANAGEMENT",
      display_name: "Topup Management",
      icon: faCommentDollar,
    },
  },
  {
    name: "Credit Request History",
    path: "CreditRequestHistory",
    icon: faList,
    tags: ["wallets", "floats", "history", "list"],
    categories: "wallets",
    rights: "VIEW_USER_WALLETS",
    group_by_vendor: true,
    vendor: {
      code: "TOPUP_MANAGEMENT",
      display_name: "Topup Management",
      icon: faCommentDollar,
    },
  },
  {
    name: "Transaction List",
    path: "transactions",
    icon: faList,
    tags: ["transaction", "list", "history"],
    categories: "transactions",
    rights: "VIEW_USER_TRANSACTIONS",
    group_by_vendor: true,
    vendor: {
      code: "TRANSACTION_MANAGEMENT",
      display_name: "Transaction Management",
      icon: faFileInvoiceDollar,
    },
  },
  {
    name: "System Transactions",
    path: "systemT",
    icon: faList,
    tags: ["system", "transaction"],
    categories: "transactions",
    rights: "VIEW_USER_TRANSACTIONS",
    group_by_vendor: true,
    vendor: {
      code: "TRANSACTION_MANAGEMENT",
      display_name: "Transaction Management",
      icon: faFileInvoiceDollar,
    },
  },
  {
    name: "Agent to Agent Transactions",
    path: "agenttoagent",
    icon: faList,
    tags: ["list", "agent", "agenttoagent"],
    categories: "transactions",
    rights: "VIEW_USER_TRANSACTIONS",
    group_by_vendor: true,
    vendor: {
      code: "TRANSACTION_MANAGEMENT",
      display_name: "Transaction Management",
      icon: faFileInvoiceDollar,
    },
  },
  {
    name: "Bulk Approve Account Openings",
    path: "xpressAcount",
    icon: faCheckDouble,
    tags: ["acount", "ecobank"],
    categories: "transactions",
    rights: "IS_SUPERADMIN",
    group_by_vendor: true,
    vendor: {
      code: "TRANSACTION_MANAGEMENT",
      display_name: "Transaction Management",
      icon: faFileInvoiceDollar,
    },
  },
  {
    name: "Financial Status",
    path: "financialStatus",
    icon: faMoneyCheckAlt,
    tags: ["balance", "account", "wallet"],
    categories: "wallets",
    rights: "VIEW_USER_WALLETS",
    group_by_vendor: true,
    vendor: {
      code: "FINANCES_MANAGEMENT",
      display_name: "Finances Management",
      icon: faBook,
    },
  },
  {
    name: "Settle With Ecobank",
    path: "payEcobank",
    icon: faMoneyBillAlt,
    tags: ["ecobank", "settle"],
    categories: "wallets",
    rights: "MAKE_WALLET_CORRECTIONS",
    group_by_vendor: true,
    vendor: {
      code: "FINANCES_MANAGEMENT",
      display_name: "Finances Management",
      icon: faBook,
    },
  },
  {
    name: "Account Statement",
    path: "requestStatment",
    icon: faInfoCircle,
    tags: ["statement", "ecobank"],
    categories: "transactions",
    rights: "VIEW_USER_TRANSACTIONS",
    group_by_vendor: true,
    vendor: {
      code: "FINANCES_MANAGEMENT",
      display_name: "Finances Management",
      icon: faBook,
    },
  },
  {
    name: "Self transfer",
    path: "selftransfer",
    icon: faShareAltSquare,
    tags: ["transfer", "self"],
    categories: "finance",
    rights: "ACCOUNTS_MANAGEMENT",
    group_by_vendor: true,
    vendor: {
      code: "FINANCES_MANAGEMENT",
      display_name: "Finances Management",
      icon: faBook,
    },
  },
  {
    name: "Notifications",
    path: "notifacation/list",
    icon: faList,
    tags: ["notification", "list"],
    categories: "iteme",
    rights: "MANAGE_MESSAGES",
    group_by_vendor: true,
    vendor: {
      code: "MESSAGE_MANAGEMENT",
      display_name: "Message Management",
      icon: faExclamationTriangle,
    },
  },
  {
    name: "Create Notification",
    path: "notifacation/create",
    icon: faPlusSquare,
    tags: ["notification", "create"],
    categories: "iteme",
    rights: "MANAGE_MESSAGES",
    group_by_vendor: true,
    vendor: {
      code: "MESSAGE_MANAGEMENT",
      display_name: "Message Management",
      icon: faExclamationTriangle,
    },
  },
  {
    name: "List Claims",
    path: "claims",
    icon: faList,
    tags: ["claims", "list"],
    rights: "MANAGE_CLAIMS",
    categories: "claims",
    group_by_vendor: true,
    vendor: {
      code: "CLAIMS_MANAGEMENT",
      display_name: "Claims Management",
      icon: faComments,
    },
  },
  {
    name: "Claims History",
    path: "claimhistory",
    icon: faInfo,
    tags: ["claims", "history"],
    rights: "MANAGE_CLAIMS",
    categories: "claims",
    group_by_vendor: true,
    vendor: {
      code: "CLAIMS_MANAGEMENT",
      display_name: "Claims Management",
      icon: faComments,
    },
  },
  {
    name: "Add New Subagent",
    path: "subagent",
    icon: faUserPlus,
    tags: ["agents", "add", "sub", "new"],
    categories: "agents",
    rights: "IS_SUPERAGENT",
    group_by_vendor: true,
    vendor: {
      code: "SUBAGENT_MANAGEMENT",
      display_name: "Subagent Management",
      icon: faUsers,
    },
  },
  {
    name: "List Subagents",
    path: "list/subagent",
    icon: faList,
    tags: ["agent", "users", "sub", "list"],
    categories: "agents",
    rights: "IS_SUPERAGENT",
    group_by_vendor: true,
    vendor: {
      code: "SUBAGENT_MANAGEMENT",
      display_name: "Subagent Management",
      icon: faUsers,
    },
  },
  {
    name: "List Wallets",
    path: "wallets",
    icon: faList,
    tags: ["agents", "list", "wallets", "balance"],
    categories: "wallets",
    rights: "VIEW_USER_WALLETS",
    group_by_vendor: true,
    vendor: {
      code: "WALLETS_MANAGEMENT",
      display_name: "Wallets Management",
      icon: faWallet,
    },
  },
  {
    name: "Agent Activity",
    path: "agent/activity",
    icon: faUserTag,
    tags: ["agents", "Activity"],
    categories: "agents",
    rights: "VIEW_USERS",
    group_by_vendor: true,
    vendor: {
      code: "STATISTICS",
      display_name: "Statistics",
      icon: faChartLine,
    },
  },
  {
    name: "Invoices",
    path: "invoices",
    icon: faList,
    tags: ["iteme", "invoices", "list"],
    categories: "iteme",
    rights: "EXPERIMENTAL",
    group_by_vendor: true,
    vendor: {
      code: "INVOICE_MANAGEMENT",
      display_name: "Invoice Management",
      icon: faFileInvoice,
    },
  },
  {
    name: "Create Invoice",
    path: "createInvoice",
    icon: faPlusSquare,
    tags: ["invoice", "create"],
    categories: "iteme",
    rights: "EXPERIMENTAL",
    group_by_vendor: true,
    vendor: {
      code: "INVOICE_MANAGEMENT",
      display_name: "Invoice Management",
      icon: faFileInvoice,
    },
  },
  {
    name: "List Flow Customers",
    path: "flow/customers",
    icon: faList,
    tags: ["list", "flow", "customers"],
    categories: "flow",
    rights: "FLOW_ADMIN",
    group_by_vendor: true,
    vendor: {
      code: "LOAN_MANAGEMENT",
      display_name: "Loan Management",
      icon: faHandHoldingUsd,
    },
  },
  {
    name: "List Loans",
    path: "flow/loans",
    icon: faList,
    tags: ["flow", "loans", "list"],
    categories: "flow",
    rights: "FLOW_ADMIN",
    group_by_vendor: true,
    vendor: {
      code: "LOAN_MANAGEMENT",
      display_name: "Loan Management",
      icon: faHandHoldingUsd,
    },
  },

  {
    name: "Add Product",
    path: "addproduct",
    icon: faPlusSquare,
    tags: ["branding", "add", "products"],
    categories: "branding",
    rights: "MANAGE_BRANDING_PRODUCTS",
    group_by_vendor: true,
    vendor: {
      code: "BRANDING",
      display_name: "Branding",
      icon: faLifeRing,
    },
  },
  {
    name: "List Products",
    path: "products",
    icon: faListAlt,
    tags: ["branding", "list", "products"],
    categories: "branding",
    rights: "MANAGE_BRANDING_PRODUCTS",
    group_by_vendor: true,
    vendor: {
      code: "BRANDING",
      display_name: "Branding",
      icon: faLifeRing,
    },
  },
  {
    name: "List Orders",
    path: "orders",
    icon: faList,
    tags: ["branding", "orders", "list"],
    categories: "branding",
    rights: "MANAGE_BRANDING_ORDERS",
    group_by_vendor: true,
    vendor: {
      code: "BRANDING",
      display_name: "Branding",
      icon: faLifeRing,
    },
  },
  {
    name: "Orders",
    path: "tsmorders",
    icon: faList,
    tags: ["branding", "orders", "list"],
    categories: "branding",
    rights: "IS_TSM",
    group_by_vendor: true,
    vendor: {
      code: "BRANDING",
      display_name: "Branding",
      icon: faLifeRing,
    },
  },
  {
    name: "Open Orders",
    path: "openOrders",
    icon: faPlusSquare,
    tags: ["branding", "orders", "open"],
    categories: "branding",
    rights: "MANAGE_BRANDING_ORDERS",
    group_by_vendor: true,
    vendor: {
      code: "BRANDING",
      display_name: "Branding",
      icon: faLifeRing,
    },
  },
  {
    name: "Create video",
    path: "createvideo",
    icon: faVideo,
    tags: ["training", "video", "add"],
    categories: "training",
    rights: "MANAGE_TRAINING_VIDEOS",
    group_by_vendor: true,
    vendor: {
      code: "TRAINING",
      display_name: "Training",
      icon: faPhotoVideo,
    },
  },
  {
    name: "List",
    path: "videos",
    icon: faFileVideo,
    tags: ["training", "video", "list"],
    categories: "training",
    rights: "MANAGE_TRAINING_VIDEOS",
    group_by_vendor: true,
    vendor: {
      code: "TRAINING",
      display_name: "Training",
      icon: faPhotoVideo,
    },
  },
];

const _getVendors = (profile) =>
  getAdminServices.reduce((res, i) => {
    if (
      i &&
      i.group_by_vendor &&
      (_hasRight(profile?.admin_rights, i.rights) ||
        _isAgent(profile?.user_rights, i.rights)) &&
      res.filter((j) => j.code == i.vendor.code).length == 0
    )
      res.push({
        name: i.vendor.display_name,
        code: i.vendor.code,
        icon: i.vendor.icon,
        filter_by_vendor: true,
      });
    return res;
  }, []);

const adminDashboard = (profile) =>
  getAdminServices
    .concat(_getVendors(profile))
    .filter((i) => !i.group_by_vendor);

export default adminDashboard;
